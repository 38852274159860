import axios from 'axios'
import store from '@/store'
import { socket } from '@/utils/socket'
import { downloadBlob } from '../../utils/download'

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL.replace(/\/*$/, '/') + 'logic',
  withCredentials: true,
})
api.interceptors.request.use(config => {
  store.commit('Loading', true)
  return {
    ...config,
    headers: {
      ...config.headers,
      socket: socket.id,
    },
  }
})
api.interceptors.response.use(
  response => {
    store.commit('Loading', false)
    const xerror = response.headers['x-error']
    if (xerror)
      store.commit('toast', [
        decodeURIComponent(response.headers['operation-name']) +
          '\n' +
          decodeURIComponent(xerror),
        'warn',
      ])
    return response.data
  },
  error => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      if (store.state.user.isLoggedIn) store.dispatch('Logout')
    } else {
      let msg =
        error.response.data?.message ||
        error.response.data?.Status ||
        error.response.data
      const operationName = error.response.headers['operation-name']
      if (operationName) msg = decodeURIComponent(operationName) + '\n' + msg
      store.commit('toast', [msg, 'error'])
    }
    throw new Error(`Axios (rules): ${error.message}. ${error.response.data}`)
  },
)

const newState = () => ({
  groups: {
    flat: [],
    tree: [],
  },
  rules: [],
  order: [],
  allRules: [],
  conditions: {},
  output: {},
  vars: [],
  activeGroup: {},
  activeRule: {},
  page: 0, // 0 - ничего, 1 - изменение группы, 2 - изменение правила, 3 - создание правила
  refreshcheck: 0,
})

const state = newState()

const blocks = state => ({
  conditions: state.conditions,
  output: state.output,
})

const newId = state => {
  let id
  do
    id =
      'temp' + Math.abs(~~(Math.random() * Number.MAX_SAFE_INTEGER)).toString()
  while (
    (JSON.stringify(state.conditions) + JSON.stringify(state.output)).includes(
      id,
    )
  )
  return id
}

const newCondition = state => ({
  id: newId(state),
  operation: 'and',
  object: '',
  object_type: 'val',
  relation: '',
  value: '',
  value_type: 'val',
  object_class: '',
  relation_no: '0',
  delete_existed: '0',
})

const sortKeys = v => {
  const { id, name, active } = v
  return { id, name, active, ...v }
}

//Поднимает и удаляет пустые группы и условия
const DeleteEmpty = (arr, state) => {
  // return
  if (!arr) return

  const Delete = arr => {
    if (arr.groups?.length) {
      for (const item of arr.groups) {
        if (!item.groups?.length && !item.conditions?.length)
          arr.groups = arr.groups.filter(e => e.id != item.id)
        Delete(item)

        // if (item.conditions?.length == 1 && !item.groups?.length) {
        //   arr.conditions.unshift(item.conditions[0])
        //   arr.groups = arr.groups.filter(e => e.id != item.id)
        // }
      }
      if (arr.groups.length == 1 && !arr.conditions?.length) {
        if (!arr.groups[0].groups) arr.groups[0].groups = []
        Object.assign(arr, arr.groups[0])
      }
    }
  }

  for (
    let i = 0;
    i <= (JSON.stringify(arr).match(/"groups"/g) || []).length;
    i++
  )
    Delete(arr)

  if (!arr.conditions?.length && !arr.groups?.length) {
    arr.conditions = []
    arr.conditions.push(newCondition(state))
  }
}

const ReEnableVars = state => {
  const json =
    JSON.stringify(state.conditions) + '|' + JSON.stringify(state.output)
  for (const item of state.vars) {
    const _var = item.var
    if (_var !== '$this')
      if (
        !(
          new RegExp(`"object":"${_var}"`, 'g').test(json) ||
          new RegExp(`"value":"${_var}"`, 'g').test(json)
        )
      ) {
        state.vars = state.vars.filter(e => e.var !== item.var)
      }
  }
}

const toOrder = group => {
  const order = []
  if (group.groups) {
    for (let entry of group.groups) {
      const orderItem = {
        id: entry.id,
        isGroup: true,
        groups: toOrder(entry),
      }
      order.push(orderItem)
    }
  }
  if (group.conditions) {
    for (let entry of group.conditions) {
      const orderItem = {
        id: entry.id,
        isGroup: false,
      }
      order.push(orderItem)
    }
  }
  return order
}

const mutations = {
  reset(state) {
    Object.assign(state, newState())
  },
  ChangeGroupOperation(state, payload) {
    const arr = blocks(state).conditions

    const Change = arr => {
      if (arr.groups?.length) {
        for (const item of arr.groups) {
          if (item.id == payload.id) {
            item.operation = payload.operation
          } else {
            Change(item)
          }
        }
      }
    }
    Change(arr)
  },
  Unite(state, payload) {
    const arr = blocks(state)[payload]
    const items = []

    const Find = (group, depth = 0) => {
      if (group.groups?.length) {
        for (const item of group.groups) {
          if (item.conditions?.length) {
            for (const condition of item.conditions)
              if (condition.checked) {
                items.push({
                  obj: condition,
                  group: item.id,
                  depth: depth,
                })
              }
            item.conditions = item.conditions.filter(e => !e.checked)
          }
          Find(item, depth + 1)
        }
      }
    }
    for (const condition of arr.conditions)
      if (condition.checked) {
        items.push({
          obj: condition,
          group: arr.id,
          depth: 0,
        })
      }
    arr.conditions = arr.conditions.filter(e => !e.checked)
    Find(arr)

    if (!items.length) return

    for (const item of items) item.obj.checked = false
    const groupId = items.reduce((best, e) =>
      best.group < e.group ? e : best,
    ).group

    const newGroup = {
      id: newId(state),
      operation: 'and',
      conditions: items.map(e => e.obj),
    }

    for (let i = 0; newGroup.num === undefined; i++)
      if (
        !JSON.stringify([state.output, state.conditions]).includes(
          `"num":"${i}"`,
        )
      )
        newGroup.num = `${i}`

    const Insert = group => {
      if (group.groups?.length) {
        for (const item of group.groups) {
          if (item.id == groupId) {
            if (!item.groups) item.groups = []
            item.groups.push(newGroup)
          } else Insert(item)
        }
      }
    }
    if (arr.id == groupId) {
      if (!arr.groups) arr.groups = []
      arr.groups.push(newGroup)
    } else Insert(arr)

    DeleteEmpty(arr, state)
    state.refreshcheck++
    this.commit('SetOrder', [])
  },
  Sever(state, id) {
    const arr = blocks(state).conditions

    const Sever = group => {
      if (group.groups?.length) {
        for (const item of group.groups)
          if (item.id == id) {
            if (item.groups?.length)
              group.groups = [...item.groups, ...group.groups]
            if (item.conditions?.length)
              group.conditions = [...item.conditions, ...group.conditions]
            group.groups = group.groups.filter(e => e.id != id)
          } else Sever(item)
      }
    }

    Sever(arr)

    DeleteEmpty(arr, state)

    this.commit('SetOrder', [])
  },
  UpdateCondition(state, payload) {
    const Update = group => {
      if (group.conditions?.length)
        for (const item of group.conditions)
          if (item.id == payload.id) Object.assign(item, payload)

      if (group.groups?.length) for (const item of group.groups) Update(item)
    }
    Update(state.conditions)
    Update(state.output)
    ReEnableVars(state)
  },
  AddCondition(state, payload) {
    const arr = blocks(state)[payload]
    arr.conditions.push(newCondition(state))
    this.commit('SetOrder', [])
  },
  DeleteCondition(state, payload) {
    const arr = blocks(state)[payload.block]

    const Delete = arr => {
      if (arr.groups?.length) {
        for (const item of arr.groups) Delete(item)
      }
      if (arr.conditions?.length)
        arr.conditions = arr.conditions.filter(e => e.id != payload.id)
    }

    Delete(arr)
    DeleteEmpty(arr, state)
    ReEnableVars(state)

    this.commit('SetOrder', [])
  },
  ToggleActiveGroup(state, payload) {
    if (payload.rules) for (const rule of payload.rules) rule.select = false
    state.activeGroup = {}
    state.activeRule = {}
    state.page = 1
    setTimeout(() => (state.activeGroup = payload))
  },
  ToggleActiveRule(state, payload) {
    state.activeRule = payload
    state.page = 2
  },
  SetPage(state, page) {
    if (page == 0) {
      state.activeGroup = {}
      state.activeRule = {}
    }
    if (page == 3) state.activeRule = {}
    state.page = page
  },
  SetVar(state, payload) {
    if (payload.var === '$this' && state.vars.length) return
    state.vars = state.vars.filter(e => e.var != payload.var)
    state.vars.push(payload)
  },
  SetConditions(state, payload) {
    state.vars = []
    state.rules = structuredClone(payload)
    state.conditions = payload.find(e => e.num != 0)
    state.output = payload.find(e => e.num == 0)
    DeleteEmpty(state.conditions, state)
    DeleteEmpty(state.output, state)
  },
  SetGroups(state, payload) {
    state.groups.flat = payload.flatGroups
    state.groups.tree = payload.groups

    state.allRules = payload.flatGroups.reduce((res, group) => {
      if (!group.rules?.length) return res
      return [
        ...res,
        ...group.rules.map(rule => {
          let endpoints = group.endpoints
          let parent = group.parent
          while (!endpoints?.length && parent != 0) {
            const gParent = payload.flatGroups.find(e => e.id == parent)
            endpoints = gParent?.endpoints
            parent = gParent?.parent
          }
          return { ...rule, endpoints }
        }),
      ]
    }, [])
  },
  SetOrder(state, payload) {
    if (payload.length === 0) {
      state.order = toOrder(state.conditions)
    } else {
      state.order = payload
    }
  },
}

const InsertGroup = (tree, newGroup) => {
  const Find = group => {
    if (!group.groups) return
    for (const item of group.groups) {
      if (item.id == newGroup.parent) {
        if (!item.groups) item.groups = []
        item.groups.push(newGroup)
      }
      Find(item)
    }
  }
  if (newGroup.parent == 0) {
    if (!tree.groups) tree.groups = []
    tree.groups.push(newGroup)
  } else Find(tree)
}

const DeleteGroup = (tree, id) => {
  const Find = group => {
    if (!group.groups) return
    group.groups = group.groups.filter(e => e.id != id)
    if (!group.groups.length) delete group.groups
    else for (const item of group.groups) Find(item)
  }
  Find(tree)
}

const DeleteRules = (tree, ids) => {
  const rules = []
  const Delete = group => {
    if (group.rules) {
      for (const rule of group.rules)
        if (ids.includes(rule.id)) rules.push(rule)
      group.rules = group.rules.filter(e => !ids.includes(e.id))
      if (!group.rules.length) delete group.rules
    }
    if (group.groups) for (const item of group.groups) Delete(item)
  }
  Delete(tree)
  return rules
}

const InsertRules = (tree, rules, id) => {
  const Insert = (group, rules, id) => {
    if (group.id == id) {
      if (!group.rules) group.rules = []
      group.rules.push(...rules)
    }
    if (group.groups) for (const item of group.groups) Insert(item, rules, id)
  }
  Insert(tree, rules, id)
}

const ReUpdateGroup = context => {
  for (const group of context.state.groups.flat)
    if (group.id == context.state.activeGroup.id) {
      context.commit('ToggleActiveGroup', group)
      break
    }
}

const GetGroupsSubtree = (tree, groupId) => {
  let subTree = structuredClone(
    tree.groups.find(e =>
      new RegExp(`"id":"${groupId}","name":"[^"]*",(?!"active":)`, 'g').test(
        JSON.stringify(e),
      ),
    ),
  )
  const DeleteExtra = group => {
    delete group.rules
    if (group.id == groupId) {
      if (groupId == 'tempID') delete group.id
      delete group.groups
    } else {
      group.groups = [
        group.groups.find(e =>
          new RegExp(
            `"id":"${groupId}","name":"[^"]*",(?!"active":)`,
            'g',
          ).test(JSON.stringify(e)),
        ),
      ]
      if (group.groups[0]) DeleteExtra(group.groups[0])
    }
  }
  DeleteExtra(subTree)
  return { groups: [subTree] }
}

const GetRuleSubtree = (tree, ruleId) => {
  let subTree = structuredClone(
    tree.groups.find(e =>
      new RegExp(`"id":"${ruleId}","name":"([^"]*)","active":`, 'g').test(
        JSON.stringify(e),
      ),
    ),
  )
  let found = false
  const DeleteExtra = group => {
    if (group.rules?.length) {
      group.rules = group.rules.filter(e => e.id == ruleId)
      if (group.rules.length === 1) {
        delete group.groups
        found = true
        if (ruleId == 'tempID') delete group.rules[0].id
      } else delete group.rules
    }
    if (!found && group.groups?.length) {
      group.groups = [
        group.groups.find(e =>
          new RegExp(`"id":"${ruleId}","name":"([^"]*)","active":`, 'g').test(
            JSON.stringify(e),
          ),
        ),
      ]
      if (group.groups[0]) DeleteExtra(group.groups[0])
    }
  }
  DeleteExtra(subTree)
  return { groups: [subTree] }
}

const actions = {
  async export(context) {
    const rules = structuredClone(
      context.state.activeGroup.rules.filter(e => e.checked),
    )
    if (!rules.length) return
    await Promise.all(
      rules.map(async e => (e.conditions = await api.get('rule/' + e.id))),
    )
    downloadBlob(
      JSON.stringify(rules).replace(/"id":"\d+",/g, ''),
      'export.json',
      'application/json',
    )
  },
  async import(context, data) {
    for (let item of data) {
      try {
        const tree = { groups: context.state.groups.tree }
        const ids = context.state.allRules.map(e => e.id)
        delete item.checked
        item.id = 'tempID'
        item = sortKeys(item)
        InsertRules(tree, [item], context.state.activeGroup.id)
        await api.patch(
          'rules/',
          JSON.stringify(GetRuleSubtree(tree, 'tempID')),
        )
        await context.dispatch('LoadGroups')
        ReUpdateGroup(context)
        const newId = context.state.allRules.find(e => !ids.includes(e.id)).id
        await api.patch('rule/' + newId, item.conditions)
      } catch (e) {
        console.warn(e)
      }
    }
  },
  async openRule(context, id) {
    const group = context.state.groups.flat.find(group =>
      group.rules?.some(rule => rule.id == id),
    )
    if (group) {
      const rule = group.rules.find(e => e.id == id)
      if (rule) {
        context.commit('ToggleActiveGroup', group)
        setTimeout(() => context.commit('ToggleActiveRule', rule), 100)
        await context.dispatch('LoadConditions', rule)
        sessionStorage.setItem('openRule', id)
      }
    }
  },
  async SaveRule(context, rule) {
    rule = structuredClone(rule)
    const tree = { groups: context.state.groups.tree }
    const groupId = rule.parentObj.id
    delete rule.parentObj
    for (const [key, value] of Object.entries(rule))
      if (typeof value == 'boolean') rule[key] = +value
    DeleteRules(tree, [rule.id])
    InsertRules(tree, [rule], groupId)
    await api.patch('rules', JSON.stringify(GetRuleSubtree(tree, rule.id)))

    let result = rule.constraint
      ? [context.state.conditions]
      : [context.state.output, context.state.conditions]
    const promises = []

    if (
      rule.constraint &&
      context.state.output &&
      !context.state.output.id.includes('temp')
    ) {
      await api.delete(
        'rule/' +
          context.state.activeRule.id +
          '/group/' +
          context.state.output.id,
      )
    }

    const old = { groups: [], conditions: [] }
    const _new = { groups: [], conditions: [] }

    const Find = (tree, result) => {
      if (tree.conditions?.length)
        for (const item of tree.conditions) {
          result.conditions.push(item.id)
          delete item.id
          if (item.object_type === 'val' && item.object === '$this') {
            item.object_class = rule.apply_to
          }
        }
      if (tree.groups?.length)
        for (const item of tree.groups) {
          result.groups.push(item.id)
          Find(item, result)
        }
    }
    for (const item of context.state.rules) Find(item, old)
    for (const item of result) Find(item, _new)
    for (const group of old.groups)
      if (!_new.groups.includes(group))
        promises.push(
          api.delete('rule/' + context.state.activeRule.id + '/group/' + group),
        )
    for (const condition of old.conditions) {
      // if(!_new.conditions.includes(condition))
      // if (!condition.includes('temp'))
      // promises.push(
      // api.delete(
      //   'rule/' + context.state.activeRule.id + '/condition/' + condition,
      // ),
      // )
    }
    result = JSON.stringify(result)
    result = result.replace(/"id":"temp-*\d*",*/g, '')
    await Promise.all(promises)
    await context.dispatch('LoadGroups')

    await api.patch('rule/' + context.state.activeRule.id, result)

    ReUpdateGroup(context)

    context.commit('SetPage', 2)

    await context.dispatch(
      'LoadConditions',
      context.state.allRules.find(e => e.id == rule.id),
    )
  },
  async CreateRule(context, rule) {
    rule = structuredClone(rule)
    const tree = { groups: context.state.groups.tree }
    const groupId = rule.parentObj.id
    delete rule.parentObj
    for (const [key, value] of Object.entries(rule))
      if (typeof value == 'boolean') rule[key] = +value
    rule.id = 'tempID'
    rule = sortKeys(rule)

    InsertRules(tree, [rule], groupId)
    const ids = context.state.allRules.map(e => e.id)
    await api.patch('rules/', JSON.stringify(GetRuleSubtree(tree, 'tempID')))
    context.commit('SetPage', 1)
    await context.dispatch('LoadGroups')

    ReUpdateGroup(context)

    context.commit('SetPage', 2)

    await context.dispatch(
      'LoadConditions',
      context.state.allRules.find(e => !ids.includes(e.id)),
    )
  },
  async DeleteRules(context, ids) {
    if (!Array.isArray(ids)) ids = [ids]
    const promises = []
    for (const id of ids) promises.push(api.delete('rule/' + id))
    await Promise.all(promises)

    const tree = { groups: context.state.groups.tree }
    DeleteRules(tree, ids)

    context.commit('SetPage', 1)
    await context.dispatch('LoadGroups')

    ReUpdateGroup(context)
  },
  async MoveRules(context, payload) {
    const tree = { groups: context.state.groups.tree }
    DeleteRules(tree, payload.ids)

    await api.patch('move', { rules: payload.ids, target: payload.groupId })
    context.commit('SetPage', 1)
    await context.dispatch('LoadGroups')
    ReUpdateGroup(context)
  },

  async CreateGroup(context, newGroup) {
    const tree = { groups: context.state.groups.tree }
    newGroup.id = 'tempID'
    newGroup = sortKeys(newGroup)
    if (!newGroup.parent) newGroup.parent = '0'
    InsertGroup(tree, newGroup)
    const ids = context.state.groups.flat.map(e => e.id)
    await api.patch('rules', JSON.stringify(GetGroupsSubtree(tree, 'tempID')))
    context.commit('SetPage', 0)
    await context.dispatch('LoadGroups')

    context.commit(
      'ToggleActiveGroup',
      context.state.groups.flat.find(e => !ids.includes(e.id)),
    )
  },
  async SaveGroup(context, newGroup) {
    const tree = { groups: context.state.groups.tree }
    DeleteGroup(tree, newGroup.id)
    InsertGroup(tree, newGroup)
    await api.patch(
      'rules',
      JSON.stringify(GetGroupsSubtree(tree, newGroup.id)),
    )
    context.commit('SetPage', 0)
    await context.dispatch('LoadGroups')

    context.commit(
      'ToggleActiveGroup',
      context.state.groups.flat.find(e => e.id === newGroup.id),
    )
  },
  async DeleteGroup(context, id) {
    await api.delete('rules/' + id)
    context.commit('SetPage', 0)
    context.dispatch('LoadGroups')
  },
  async LoadGroups(context, payload) {
    let data
    if (payload) {
      if (typeof payload === 'boolean' && payload) {
        context.commit('reset')
      } else if (typeof payload === 'object') {
        data = payload
      }
    }
    if (!data) {
      data = await api.get('rules')
    }
    const groups = data.groups
    const flatGroups = []
    const Flat = item => {
      flatGroups.push(item)
      if (item.groups) for (const group of item.groups) Flat(group)
      delete item.groups
    }
    for (const group of structuredClone(groups)) Flat(group)

    context.commit('SetGroups', {
      flatGroups,
      groups,
    })
  },
  async LoadConditions(context, payload) {
    let data = await api.get('rule/' + payload.id)
    if (!data.length)
      data = [
        {
          id: newId(context.state),
          num: '0',
          operation: 'and',
          conditions: [newCondition(context.state)],
        },
        {
          id: newId(context.state),
          num: '1',
          operation: 'and',
          conditions: [newCondition(context.state)],
        },
      ]
    else if (data.length === 1)
      data.unshift({
        id: newId(context.state),
        num: '0',
        operation: 'and',
        conditions: [newCondition(context.state)],
      })
    context.commit('SetConditions', data)
    context.commit('ToggleActiveRule', payload)
    context.dispatch('LoadOrder')
  },

  async SaveOrder(context, payload) {
    await api.post(
      `group/${context.state.activeGroup.id}/rule/${context.state.activeRule.id}/order`,
      payload,
    )
  },
  async LoadOrder(context) {
    const data = await api.get(
      `group/${context.state.activeGroup.id}/rule/${context.state.activeRule.id}/order`,
    )
    context.commit('SetOrder', data)
  },
  async ClearOrder(context) {
    await api.delete(
      `group/${context.state.activeGroup.id}/rule/${context.state.activeRule.id}/order`,
    )
    context.commit('SetOrder', [])
  },
}

const getters = {
  vars: state => {
    const result = {}
    for (const _var of state.vars) result[_var.var] = _var.class
    return result
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}

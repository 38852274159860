import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/utils/api'
import apiLazy from '@/utils/api-lazy'
import router from '@/router'
import glossary from '@/store/modules/glossary'
import searchByClass from '@/store/modules/searchByClass'
import rules from '@/store/modules/rules'
import LicenseToast from '@/components/LicenseToast'

Vue.use(Vuex)

const getDefaultLanguage = () => {
  const possible = ['ru', 'en']
  const priority = 'ru'
  const language = (
    localStorage.getItem('language') || window.navigator.language
  ).split('-')[0]
  return possible.find(e => e == language) || priority
}

export default new Vuex.Store({
  state: {
    toasts: [],
    language: getDefaultLanguage(),
    endpoints: [],
    user: {},
    isLoading: 0,
    appRefreshKey: 0,
  },
  mutations: {
    toast(state, payload) {
      state.toasts.push(payload)
    },
    clearToasts(state) {
      state.toasts = []
    },
    Loading(state, payload) {
      if (payload == 'disable') state.isLoading--
      else if (payload == null) state.isLoading = 0
      else {
        if (payload) state.isLoading++
        else state.isLoading = Math.max(state.isLoading - 1, 0)
      }
    },
    SetLanguage(state, lang) {
      localStorage.setItem('language', lang)
      state.language = lang
    },
    SetUser(state, payload) {
      if (payload.originator) {
        state.user = {
          isLoggedIn: true,
          username: payload.name || '???',
          originator: payload.originator,
          endpoint: payload.endpoints?.find(e => e.Code == payload.endpoint)
            .Name,
          endpointId: payload.endpoints?.find(e => e.Code == payload.endpoint)
            .Code,
          access: payload.access,
        }
        state.endpoints = payload.endpoints
        localStorage.setItem('endpoint', state.user.endpoint)
      } else {
        state.user = { isLoggedIn: false }
      }
    },
    SetEndpoint(state, payload) {
      state.user.endpointId = payload
      state.user.endpoint = state.endpoints.find(e => e.Code == payload)?.Name
      console.log(payload)
    },
    Refresh(state, lazy) {
      state.appRefreshKey = state.appRefreshKey + (lazy ? 2 : 1)
    },
    resetUser(state) {
      state.user = {}
    },
  },
  actions: {
    async GetLicense(context) {
      try {
        let response = await api.get('/check-license')
        if (response.status !== 'success') {
          const content = {
            component: LicenseToast,
            props: {
              text: response.message,
            },
          }
          this._vm.$toast(content, {
            type: response.status,
            timeout: false,
            position: 'top-center',
            icon: false,
            draggablePercent: 2,
          })
        }
      } catch (e) {
        console.error(e)
      }
      setTimeout(() => context.dispatch('GetLicense'), 60 * 60 * 1_000)
    },
    async SetLanguage(context, lang) {
      context.commit('SetLanguage', lang)
      await api.patch('lang/' + lang)
      sessionStorage.clear()
      context.commit('Refresh')
    },
    async SetEndpoint(context, code) {
      await api.patch('endpoint/' + code)
      sessionStorage.clear()
      context.commit('SetEndpoint', code)
      localStorage.setItem('endpoint', code)
      context.commit('Refresh')
    },
    async Auth(context) {
      const data = await apiLazy.get('auth')
      context.commit('SetUser', data)
    },
    async Logout(context, lazy) {
      context.commit('resetUser')
      if (!lazy) {
        await api.post('logout')
      }
      context.commit('Refresh', true)
      context.dispatch('login')
    },
    login() {
      const front = encodeURIComponent(window.location.origin)
      const href = encodeURIComponent(window.location.href)
      const back = import.meta.env.VITE_SERVER_URL.replace(/(^\/)|(\/$)/g, '')
      window.location =
        window.location.origin +
        '/' +
        back +
        '/oauth/login' +
        '?front=' +
        front +
        '&href=' +
        href +
        '&back=' +
        encodeURIComponent(back)
    },
  },
  getters: {},
  modules: {
    glossary,
    searchByClass,
    rules,
  },
})
